'use client'

import 'resize-observer-polyfill'

import { env } from '@/env'
import { formats } from '@/i18n/formats'
import { getQueryClient } from '@/lib/get-query-client'
import { logError } from '@/utils/logError'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import {
  AbstractIntlMessages,
  IntlError,
  IntlErrorCode,
  NextIntlClientProvider,
} from 'next-intl'
import PlausibleProvider from 'next-plausible'
import { useRouter } from 'next/navigation'
import { Suspense, lazy } from 'react'
import { I18nProvider, RouterProvider } from 'react-aria-components'
import { Toaster } from 'react-hot-toast'
import { IntercomProvider } from 'react-use-intercom'

interface Props {
  children: React.ReactNode
  locale: string
  messages: Record<string, string | AbstractIntlMessages>
  session: Session | null
  timeZone: string
}

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NonNullable<
      Parameters<ReturnType<typeof useRouter>['push']>[1]
    >
  }
}

const ReactQueryDevtoolsPreview = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
)

function onError(error: IntlError) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error

    // Prompt suggestions
    const assistantRegex =
      /assistant\.suggestions\.suggestion\d+\.(title|prompt|text)/

    // Provider faq
    const faqRegex = /provider\.[^\.]+\.(faq\d+)\.(title|text)/

    const linkRegex = /provider\.[^\.]+\.linkArticle\.(href|text)/

    if (
      !assistantRegex.test(error.message) &&
      !faqRegex.test(error.message) &&
      !linkRegex.test(error.message)
    ) {
      console.log(error.message)
    }
  } else {
    logError('i18n', error)
  }
}

function getMessageFallback({
  namespace,
  key,
  error,
}: {
  namespace: string | undefined
  key: string
  error: IntlError
}) {
  const path = [namespace, key].filter((part) => part != null).join('.')

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return path
  } else {
    return 'Missing: ' + path
  }
}

export function GlobalProviders({
  children,
  locale,
  messages,
  session,
  timeZone,
}: Props) {
  const router = useRouter()
  const queryClient = getQueryClient()

  return (
    <NextIntlClientProvider
      formats={formats}
      locale={locale}
      messages={messages}
      timeZone={timeZone}
      onError={onError}
      getMessageFallback={getMessageFallback}
    >
      <I18nProvider locale={locale}>
        <RouterProvider navigate={router.push}>
          <SessionProvider
            refetchInterval={10 * 60} // Re-fetch session every 10 minutes
            refetchOnWindowFocus={true} // Re-fetches session when window is focused
            refetchWhenOffline={false}
            session={session}
          >
            <QueryClientProvider client={queryClient}>
              <IntercomProvider appId={env.NEXT_PUBLIC_INTERCOM_APP_ID}>
                <PlausibleProvider domain="app.players1st.sport">
                  {children}
                </PlausibleProvider>
              </IntercomProvider>

              <ReactQueryDevtools initialIsOpen={false} />
              {env.NEXT_PUBLIC_VERCEL_ENV === 'preview' && (
                <Suspense fallback={null}>
                  <ReactQueryDevtoolsPreview initialIsOpen={false} />
                </Suspense>
              )}
            </QueryClientProvider>
            <Toaster
              position="top-center"
              toastOptions={{
                className: 'text-2',
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'var(--shadow-5)',
                  borderRadius: 'var(--radius-4)',
                  padding: 'var(--space-2) var(--space-4)',
                  fontWeight: 'var(--font-weight-medium)',
                  maxWidth: '500px',
                },
                success: {
                  duration: 3000,
                  style: {
                    backgroundColor: 'var(--accent-green-9)',
                    color: 'var(--accent-green-contrast)',
                  },
                },
                error: {
                  duration: 6000,
                  style: {
                    backgroundColor: 'var(--accent-red-9)',
                    color: 'var(--accent-red-contrast)',
                  },
                },
              }}
            />
          </SessionProvider>
        </RouterProvider>
      </I18nProvider>
    </NextIntlClientProvider>
  )
}
