import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  server: {
    AUTH_CLIENT_ID: z.string().min(1),
    AUTH_CLIENT_SECRET: z.string().min(1),
    AUTH_ENDPOINT: z.string().min(1),
    AUTH_SCOPE: z.string().min(1),
    AUTH_SECRET: z.string().min(1),
    AZURE_AI_SEARCH_API_KEY: z.string().min(1),
    AZURE_AI_SEARCH_ENDPOINT: z.string().min(1),
    AZURE_AI_SEARCH_INDEX: z.string().min(1),
    AZURE_OPENAI_API_KEY: z.string().min(1),
    AZURE_OPENAI_DEPLOYMENT_ID: z.string().min(1),
    AZURE_OPENAI_ENDPOINT: z.string().min(1),
    AZURE_RESOURCE_NAME: z.string().min(1),
    AZURE_STORAGE_ACCOUNT_KEY: z.string().min(1),

    KAFKA_CLIENT_ID: z.string().min(1),
    KAFKA_BROKER: z.string().min(1),
    KAFKA_USERNAME: z.string().min(1),
    KAFKA_PRODUCER_PASSWORD: z.string().min(1),
    KAFKA_CONSUMER_PASSWORD: z.string().min(1),
    KAFKA_TOPIC: z.string().min(1),

    INTERCOM_IDENTITY_VERIFICATION_SECRET: z.string().min(1),
    NEXTAUTH_SECRET: z.string().min(1),
    VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  },
  client: {
    NEXT_PUBLIC_API_ENDPOINT: z.string().min(1),
    NEXT_PUBLIC_API_ENDPOINT_URL: z.string().min(1),
    NEXT_PUBLIC_AUTH_ENDPOINT: z.string().min(1),
    NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME: z.string().min(1),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT,
    NEXT_PUBLIC_API_ENDPOINT_URL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
    NEXT_PUBLIC_AUTH_ENDPOINT: process.env.NEXT_PUBLIC_AUTH_ENDPOINT,
    NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME:
      process.env.NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME,
    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
})
